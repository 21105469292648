import React, { useState } from "react";

import "./styled.css"

import Logo from "../assets/images/logoGSK.png"

import Form from "./form";
import Confirmation from "./confirmation";

export default function FormIndex() {
  const [step, setStep] = useState("a");

  return (
    <div className="div-form-geral">
        {step === "a" && <Form setStep={setStep} />}
        {step === "b" && <Confirmation setStep={setStep} />}

        <img src={Logo} className="logo" />
    </div>
  )
}
