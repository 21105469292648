import React, { useEffect, useState } from "react";
import axios from "axios";

import { Card, CardTitle, CardBody, Row, Col } from "reactstrap";

import "./styled.css";

import InputMask from "inputmask";

//service
import { addNewUserApi, verifyEmail } from "../utils/services";

export default function Form(props) {
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [crmError, setCrmError] = useState("");
  //alert
  const [success_dlg, setsuccess_dlg] = useState(false);

  async function submitForm(e) {
    if (e.target[0].value === "") {
      setNameError("O campo Nome é obrigatório");
      return;
    } else setNameError("");

    if (e.target[1].value === "") {
      setEmailError("O campo email é obrigatório");
      return;
    } else setEmailError("");

    if (e.target[2].value === "") {
      setPhoneError("O campo Telefone é obrigatório");
      return;
    } else setPhoneError("");

    if (e.target[3].value === "") {
      setCrmError("O campo CRM é obrigatório");
      return;
    } else setCrmError("");

    //array para enviar os dados para o mice
    const data = [
      //nome
      {
        id: 270625,
        value: e.target[0].value,
      },
      //email
      {
        id: 270626,
        value: e.target[1].value,
      },
      //telefone
      {
        id: 275496,
        value: e.target[2].value,
      },
      //crm
      {
        id: 275498,
        value: e.target[3].value,
      },
    ];

    //verificar email duplicado
    await verifyEmail(e.target[1].value, 90928)
      .then((response) => {
        if (response.status === 200) {
          setEmailError("Email já cadastrado");
          return;
        } else {
          setEmailError("");

          //enviando dados para o mice
          addNewUserApi(data, null, 90928)
            .then((response) => {
              sendEmailApi(
                e.target[1].value,
                "75º CONGRESSO BRASILEIRO DE DERMA",
                getHtmlString(e.target[0].value)
              );
              props.setStep("b");
            })
            .catch((error) => console.log(error));
        }
      })
      .catch((error) => console.log("error", error));
  }

  //enviar email
  async function sendEmailApi(emailTo, subject, htmlContent) {
    var method = "https://api-externa.inteegra.com.br/email/api/emails";
    const token = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2dpbiI6Ik9iS1RnYWxWaEUzWnUrYzJKeHZQV2J6Z2RZb0ZDMUdEIiwiY29udHJhY3RDb2RlIjoicmJmK3BQamJONG89IiwiYWdlbmN5Q29kZSI6IkwreUgweThwOFlnPSIsInVzZXJJZCI6IjZmMCtCd3J2OWJVPSIsInByb2ZpbGUiOiJSZVZ4ekF0YkY3RT0iLCJqdGkiOiI5MTQwOTQ3OS0xY2MwLTRjMjUtYTk1Zi00MGI3MDdhNTQwZTEiLCJleHAiOjE2MTQ2MzYwMjMsImlzcyI6IkludGVlZ3JhIiwiYXVkIjoiYXBpLWNsaWVudCJ9.EcQWea_Z0rQFnShUCgcyuNXS42dcxrStlE3Dz7d3JPY`;

    const _params = {
      to: [emailTo.trim()],
      from: "naoresponder@inteegra.com.br",
      subject: subject,
      htmlContent: htmlContent,
    };

    return new Promise((resolve, reject) => {
      const options = {
        url: method,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + token,
        },
        data: _params,
      };
      axios(options)
        .then((res) => {
          setsuccess_dlg(true);
          resolve(true);
        })
        .catch((err) => {
          console.log("Erro sendEmailApi", err, options);
          reject(err);
        });
    });
  }

  //conteúdo do email
  function getHtmlString(name) {
    return `
    <!DOCTYPE html>
<html>
  <head>
    <meta charset="utf-8" / >
    <style type="text/css"></style>
    <title></title>
  </head>
  <body>
    <div
      class="panel contorno center"
      style="
        background: #ee7e08;
        color: #0064a0;
        border-radius: 7px;
        width: 873px;
      "
    >
      <div class="row"><br /></div>
      <div
        class="panel corpo"
        style="
          font-family: Calibri;
          color: #4f4f4f;
          font-size: 20px;
          background: #ffffff;
          margin: 20px;
          margin-top: 20px;
          border-radius: 7px;
          word-break: break-word;
          justify-content: center;
          padding: 20px;
        "
      >
        <br />
        <table
          align="center"
          border="0"
          cellpadding="1"
          cellspacing="1"
          style="height: 249px"
        >
          <tbody>
            <tr>
              <td>
                <p
                  style="
                    font-size: 20px;
                    font-family: Calibri;
                    color: #000000;
                    text-align: left;
                  "
                >
                  Olá Dr(a) ${name}, tudo bem?
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p
                  style="
                    font-size: 20px;
                    font-family: Calibri;
                    color: #000000;
                    text-align: left;
                  "
                >
                  Nesse congresso, a GSK apresentará dois simpósios sobre os
                  principais assuntos de Dermatologia, trazendo informações
                  atualizadas e novas perspectivas.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p
                  style="
                    font-size: 20px;
                    font-family: Calibri;
                    color: #000000;
                    text-align: left;
                  "
                >
                  Na sexta (26/08), às 13h05min, teremos um simpósio com o tema
                  <strong
                    >O Papel do Dermatologista na Prevenção do Herpes
                    Zoster</strong
                  >, apresentado pelo Dr. Omar Luppi – Professor Adjunto de
                  Dermatologia da Universidade Federal do Estado do Rio de
                  Janeiro (Uni-Rio) – e Igor Sampietri –  Gerente de Educação
                  Médica da GSK.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p
                  style="
                    font-size: 20px;
                    font-family: Calibri;
                    color: #000000;
                    text-align: left;
                  "
                >
                  No sábado (27/08), às 13h05min, teremos um simpósio com o tema
                  <strong
                    >Desvendando a Eficácia e a Segurança da Dutasterida no
                    Tratamento da Alopecia Androgenética Masculina</strong
                  >, apresentado pela Dra. Juliany Estefan – Gerente Médica da
                  GSK e Dermatologista pela SBD.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p
                  style="
                    font-size: 20px;
                    font-family: Calibri;
                    color: #000000;
                    text-align: left;
                  "
                >
                  Participe das nossas aulas e se mantenha informado sobre esses
                  tópicos!
                </p>
                <p
                  style="
                    font-size: 20px;
                    font-family: Calibri;
                    color: #000000;
                    text-align: left;
                  "
                >
                  #AheadTogether na Dermatologia
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <br /><br />
      </div>
      <div class="row"></div>
      <br />
    </div>
  </body>
</html>


    `;
  }

  useEffect(() => {
    let phone = document.getElementById("phone");
    InputMask("(99)99999-9999").mask(phone);
  }, []);

  return (
    <Card className="card-form">
      <CardBody className="card-body-form">
        <CardTitle>Preencha o formulário:</CardTitle>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitForm(e);
          }}
        >
          <Row>
            <div>
              <label className="form-label">Nome*</label>
              <input className="form-control" type="text" />
              {nameError && <p style={{ color: "red" }}>{nameError}</p>}
            </div>
          </Row>
          <Row>
            <div>
              <label className="form-label">Email*</label>
              <input className="form-control" type="email" />
              {emailError && <p style={{ color: "red" }}>{emailError}</p>}
            </div>
          </Row>
          <Row>
            <div>
              <label className="form-label">Telefone*</label>
              <input className="form-control" type="text" id="phone" />
              {phoneError && <p style={{ color: "red" }}>{phoneError}</p>}
            </div>
          </Row>
          <Row>
            <div>
              <label className="form-label">CRM*</label>
              <input className="form-control" type="text" />
              {crmError && <p style={{ color: "red" }}>{crmError}</p>}
            </div>
          </Row>
          <div className="div-btn">
            <button
              className="btn btn-primary btn-block waves-effect waves-light button-submit"
              type="submit"
            >
              Enviar
            </button>
          </div>
        </form>
      </CardBody>
    </Card>
  );
}
