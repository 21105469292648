import React from "react";

//reactstrap
import { Card, CardBody, CardTitle, Col, Row, Button } from "reactstrap";

export default function Confirmation(props) {
  return (
    <Card className="">
      <CardBody>
        <CardTitle>Confirmação</CardTitle>
        <Row>
          <div className="mt-3">
            <p>Cadastro realizado com sucesso!</p>
          </div>
        </Row>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Button
            color="primary"
            className="btn btn-success waves-effect waves-light"
            onClick={() => props.setStep("a")}
          >
            Voltar
          </Button>
        </div>
      </CardBody>
    </Card>
  );
}
