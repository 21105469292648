import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/theme.scss";

import Form from "./form";

function App() {
  return (
    <div
      style={{
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Form />
    </div>
  );
}

export default App;
